import React from "react"
import $ from "jquery"

import vascepa_logo from "../../images/vascepa-logo.svg"

const LeavingPageModal = () => {
  const handleClose = () => $("#leavingPageModal").modal("hide")
  return (
    <>
      <div
        className="modal fade"
        id="leavingPageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="modal-close">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
            <img src={vascepa_logo} alt=""/>
            <p className="continue-para">You are about to leave vascepa.com</p>
            </div>
            <div className="modal-footer">
              
              <a
                className="cta-btn"
                id="leavingModalLink"
                href=""
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleClose}
              >
                Continue
              </a>
              <button
                type="button"
                className="cta-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default LeavingPageModal
