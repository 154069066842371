import React from "react"
import { TextInput } from "react"
import { Link } from "gatsby"
import "./savings.css"
import widgetIcon from "../../images/savingswidget/icons/dollar.png"
import savingsClose from "../../images/savingswidget/icons/close.png"
import $ from "jquery"

export default class Savings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 70,
      topOffset: 0
    };
  }
  
  componentDidUpdate() {
    
  }
  componentDidMount() {
    const {positionTarget, positionTargetOffset} = this.props;

    let isClosed = JSON.parse(localStorage.getItem('home-page-alert-closed')) || false;

    if(positionTarget) {
      let el = document.querySelector(positionTarget);
      if(el) {
        let top = el.getBoundingClientRect().top;

        if(!isClosed && window.innerWidth > 1000) {
          this.setState({
            top: top
          });
        }
      }
    }
    
    if(positionTargetOffset) {
      let el = document.querySelector(positionTargetOffset);
      if(el) {
        let computed_style = window.getComputedStyle(el);
        let topOffset = computed_style.getPropertyValue('height');
        
        if(!isClosed) {
          this.setState({
            topOffset: topOffset.substring(0, topOffset.length - 2)
          });
        }
      }
    }

    window.addEventListener("scroll", this.handleScroll);
    //this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    if (window.innerWidth > 1000) {
      let isClosed = JSON.parse(localStorage.getItem('home-page-alert-closed')) || false;

      if(isClosed) {
        this.setState({
          top: 70
        });
      }
    }

    //// close element on scroll
    var e = document.querySelector(".savings-outer")
    var savingWidget = document.querySelector("#savingsWidget")
    savingWidget.classList.remove("savings-open")
    if (document.getElementsByClassName("savings-thanks").length > 0 && window.innerWidth > 1000) {
      this.closeWidget()
    }
    savingWidget.classList.add("savings-closed")
    /// Handle element positioning on scroll.
    if (window.innerWidth > 1000) {
      if (this.distanceCheck(e)) {
        e.classList.add("fixed-savings");
        e.setAttribute("style", `top: ${this.state.topOffset}px;`);
      } else {
        e.classList.remove("fixed-savings")
        e.setAttribute("style", `top: ${this.state.top}px;`);
      }
    } else {
      e.setAttribute("style", "position:static;")
    }
  }

  distanceCheck = element => {
    let isClosed = JSON.parse(localStorage.getItem('home-page-alert-closed')) || false;

    if(isClosed || window.innerWidth < 1000) {
      this.setState({
        topOffset: 0
      });
    }
    
    var top = window.getComputedStyle(element).top
    top = top.substring(0, top.length - 2)
    top = parseInt(top) + 150 - this.state.topOffset;
    
    if (window.scrollY > top) {
      return true
    } else {
      return false
    }
  }

  toggleWidget = (event) => {
    
    var e = document.getElementById("savingsWidget")
    if (e.classList.contains("savings-closed")) {
      e.classList.remove("savings-closed");
      /* document
        .getElementById("savings-toggle-click-overlay")
        .setAttribute("style", "height:20px;width:20px;") */
      e.classList.add("savings-open")
      document.getElementsByClassName("savings-toggle")[0].setAttribute("aria-expanded","true")
    } else {
      e.classList.remove("savings-open")
      /* document
        .getElementById("savings-toggle-click-overlay")
        .setAttribute("style", "height:100%;width:100%;") */
      e.classList.add("savings-closed")
      document.getElementsByClassName("savings-toggle")[0].setAttribute("aria-expanded","false")

    }
  
  }

  toggleWidgetKey = (event) =>{
    if (event.key != "Tab"  && event.key != "Enter"){
      this.toggleWidget()
    }
  }

  validateForm = () => {
    var widgetInput = document.getElementsByClassName("widget-input")[0];
    var number = widgetInput.value;
    number = number.replace(/[^0-9]/g, "");

    if (number.length === 10) {
      this.submitSignUpForm(number, function(err, res) {
        if (err) {
          // console.log(err)
        } else {
          // console.log(res)
        }
      })
      document
        .getElementsByClassName("validation_box")[0]
        .setAttribute("style", "display:none");
      document
        .getElementsByClassName("validation_box")[0]
        .innerHTML = "";

      widgetInput.setAttribute("aria-invalid", "false");
      return true;

    } else {
      var validationBox = document.getElementsByClassName("validation_box")[0];

      widgetInput.setAttribute("style", "border: 2px solid red");
      widgetInput.setAttribute("aria-invalid", "true");
      validationBox.setAttribute("style", "display:block");
      validationBox.innerHTML = "Please fill out this field.";
      widgetInput.focus();
      return false;
    }
  }

  submitSignUpForm = (number, callback) => {
    var payload = {
      mobileID: number,
      endPointTriggerChannel: 'sms'
    }

    $.ajax({
      url: "/backend/",
      data: payload,
      type: "POST",
      crossDomain: true,
      complete: function(resp) {
        // console.log("complete")
        callback(null, resp)
      },
      success: function(resp) {
        if (resp.status == 200) {
          // console.log("form submitted")
          // console.log(resp)
        } else if (resp.status == 500) {
          // console.log("err")
          // console.log(resp)
        } else {
          // console.log("else-triggered")
          // console.log(resp)
        }
      },
      error: function(resp) {
        // console.log(resp)
        if (resp.status == 200) {
          // console.log("form submitted")
          callback(null, resp)
        } else if (resp.status == 500) {
          // console.log("err")
          callback(resp)
        }
      },
    })
  }

  thanksModal = e => {
    e.preventDefault();

    if (this.validateForm()) {
      var e = document.getElementById("savingsWidget")
      var b = document.getElementById("savings-close-button")
      //var c = document.getElementById("savings-toggle-click-overlay")
      e.classList.remove("savings-open")
      e.classList.add("savings-thanks")
      document.getElementsByClassName("widget-content")[0].innerHTML = "<p class='savings-widget-thankyou' >Thank you for your submission!</p>"
      var savingsToggle = document.getElementsByClassName("savings-toggle")[0]
      savingsToggle.parentNode.removeChild(savingsToggle)
      b.classList.add("savings-close-button")
      //c.classList.add("savings-display-none")
      document.querySelector("#savings-close-button").focus();
    } else {
    }
  }

  closeWidget = () => {
    var e = document.getElementById("savingsWidget")
    e.classList.remove("savings-thanks")
    e.classList.add("savings-removed")
  }

  render() {
    const divStyles = {
      top: this.state.top + 'px'
    }
    
    return (
      <div className="savings-outer" style={divStyles} tabIndex="-1">
        <div aria-live="polite" tabIndex="0" id="savingsWidget" className="savings-closed" tabIndex="-1">
          <img
            tabIndex="0"
            id="savings-close-button"
            src={savingsClose}
            onClick={this.closeWidget}
            onKeyDown={this.closeWidget}
          />
          {/*<div
            id="savings-toggle-click-overlay"
          ></div>*/}
          <button
            tabIndex="0"
            role="button"
            aria-expanded="false"
            id="save-on-vascepa-toggle"   
            onClick={this.toggleWidget}
            onKeyDown={this.toggleWidgetKey}
            style={{border:"none",background:"rgba(255,255,255,0)"}} className="savings-toggle">
            
            <div className="savings-bar-container">
              <div className="savings-bar1"></div>
              <div className="savings-bar2"></div>
            </div>
            Save on Vascepa
          </button>

          <div tabIndex="0" className="widget-content" tabIndex="-1">
            <div className="widget-body" tabIndex="-1">
              <img alt="" className="widget-icon" src={widgetIcon}></img>
              <h4 style={{display:"inline"}}>Save on VASCEPA*</h4>
              <p style={{marginBottom:"0px"}} className="widget-paragraph">
                Register for the VASCEPA Savings Program by entering your mobile
                number below or texting VASCEPA to 26789
              </p>
            </div>
            <form className="widget-form" tabIndex="-1">
              <input
                tabIndex="0"
                id="widget-mobile-input"
                className="widget-input"
                type="number"
                role="textbox"
                name="mobileNumber"
                id="mobileNumber"
                placeholder="Mobile Number"
                aria-describedby="validation_error_msg"
              />

              <label style={{display:"none"}} htmlFor="mobileNumber">Mobile Number</label>
              
              <div id="validation_error_msg" className="validation_box"></div>
              <div className="widget-submit">
                <div tabIndex="2" role="button" aria-label="Submit" className="widget-submit-text" onKeyDown={this.thanksModal} tabIndex="0" onClick={this.thanksModal}>
                  submit <span aria-hidden="true">&gt;</span>
                </div>
              </div>
            </form>
            <div className="widget-footer">
              <div className="widget-footer-text indent" tabIndex="0">
                *For eligible patients only. Maximum savings and other terms and conditions apply.
              </div>
              <div className="widget-footer-text" tabIndex="1">
                Message & data rates may apply. Message frequency varies.
                <br />
                Once registered, text HELP for more info. Text STOP to end.
              </div>
              <div className="widget-footer-links">
                <a
                  href="https://amarincorp.com/privacy_policy.html"
                  rel="noopener noreferrer"
                  target="_blank"
                  tabIndex="0"
                >
                  ABOUT YOUR PRIVACY
                </a>
                <span aria-hidden="true">&nbsp;|&nbsp;</span>
                <a
                  href="https://engagedrx.com/va/"
                  rel="noopener noreferrer"
                  target="_blank"
                  tabIndex="0"
                >
                  TERMS AND CONDITIONS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
