import PropTypes from "prop-types"
import React from "react"
// import "./header.css"
import NavContainer from '../nav/NavContainer'
import PreHeader from '../preHeader/preHeader'



const Header = ({ siteTitle, handleOpen}) => (
    <header>
        <PreHeader handleOpen={handleOpen} />
        <NavContainer />
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
