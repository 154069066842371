import React from "react";
import "./isi.css"

const CookieNotification = (props) => {



    return (
      <div className="cookie-wrap-outer">
        <div className='cookie-wrap'>

              <p>
                This site uses cookies to give you the best possible experience. By browsing our website, you agree to our use of cookies.
                If you require further information and/or do not wish to have cookies placed when using the site, visit the <a style={{textDecoration:"underline"}} href="https://www.amarincorp.com/privacy_policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a> page.
              </p>
              <button type="button" className="cookie-close" aria-label="Close" onClick={props.closeCookie}>
                &times;
              </button>
        </div>
      </div>
    )
}


export default CookieNotification
