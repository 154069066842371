import React, {useEffect} from "react"
import $ from "jquery"

import rarf from "../../images/homepage/Red_Flag_Logo_4c_TM.png"

const RARF_modal = () => {
  const handleClose = () => $("#RARF_modal").modal("hide")

  useEffect(() => {
    let location = window.location.href.toLowerCase()
    if (!localStorage.getItem('IsModalShown') && location.includes("raisearedflag"))
    {
      $("#RARF_modal").modal("show")
      localStorage.setItem('IsModalShown',true);
    }
  })
  return (
    <>
      <div
        className="modal fade"
        id="RARF_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="modal-close">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <img src={rarf} alt="" />
              <h1 className="rarf_modal_header">Ready to Raise A Red Flag for CV risk?</h1>
              <p className="continue-para">
                You are being redirected to a website for an <span style={{"whiteSpace": "nowrap"}}>FDA-approved</span> prescription medication sponsored by Amarin Pharma, Inc.{" "}
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="cta-btn rarf"
                id="RARFModalLink"
                rel="noopener noreferrer"
                onClick={handleClose}
              >
                Continue for more information
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RARF_modal
