import React from "react";



const SVG = ({
  style = {},
  fill = '#fff',
  width = "13px",
  className = '',
  height = "10px",
  viewBox="0 0 13 10",
}) => (

  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

      <title>down-arrow</title>
      <defs>
          <path d="M8.52613047,5.03107569 L1.45450482,0.153929959 C0.879935237,-0.24214771 0,0.142211685 0,1.12185941 L0,10.8738072 C0,11.7526778 0.817656715,12.2823438 1.45450482,11.8417367 L8.52613047,6.96693459 C9.15695162,6.53335845 9.1589606,5.46465184 8.52613047,5.03107569 Z" id="path-1"></path>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1"  fillRule="evenodd">
          <g id="navDesktop" transform="translate(-944.000000, -108.000000)">
              <g id="element/NAVarrowDown" transform="translate(939.000000, 106.000000)">
                  <g id="hcc_isi_panel" transform="translate(5.000000, 2.000000)">
                      <g id="Group-4">
                          <g id="play" transform="translate(6.214286, 5.076923) rotate(-270.000000) translate(-6.214286, -5.076923) translate(1.714286, -0.923077)">
                              <mask id="mask-2" >
                                  <use xlinkHref="#path-1"></use>
                              </mask>
                              <use id="Mask"  fillRule="nonzero" xlinkHref="#path-1"></use>
                              <g id="Color" mask="url(#mask-2)">
                                  <g transform="translate(-4.000000, -7.571429)" id="color/C9C7CC">
                                      <g transform="translate(-0.000000, 0.000000)">
                                          <rect id="Rectangle"  x="0" y="0" width="14.3846154" height="25.7142857"></rect>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>

);

export default SVG;
