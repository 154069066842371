import React from "react";
import CookieNotification from './CookieNotification'
import "./isi.css"

var placeHolderHeight



export default class ISI extends React.Component {
      constructor(props) {
       super(props);
       this.state = {
         mobile: true,
         scrollTo: null
       }

    }


    componentDidMount(){
      this.parseMobile()
      setTimeout(this.setIsi, 500);
      this.checkCookie()
      window.addEventListener("scroll", this.checkArrowDirection)
      window.addEventListener("resize", this.setIsi)

      // this.setZero()

      // this.io = new IntersectionObserver(
      //   entries => {
      //     console.log(entries)
      //     this.isiSwitch()
      //   },
      //   {
      //
      //   }
      // )

     }

    componentWillUnmount(){
      window.removeEventListener("scroll", this.checkArrowDirection)
      window.removeEventListener("resize", this.setIsi)
    }


  checkArrowDirection(){
    if(document.getElementById("isi-target-container").getBoundingClientRect().top > 1 && document.getElementsByClassName("toggleISI")[0].classList.contains("flip")){
      // console.log("true")
    document.getElementsByClassName("toggleISI")[0].classList.remove("flip")
    }
    else if(document.getElementById("isi-target-container").getBoundingClientRect().top < 1){
      document.getElementsByClassName("toggleISI")[0].classList.add("flip")
    }
  }

  closeCookie = () => {
   this.setCookie("vascepaHccPrivacy", true, 365);
   this.checkCookie();
 }

 checkCookie = () => {
   const cookie = document.querySelector('.cookie-wrap')
     var cName = this.getCookie("vascepaHccPrivacy");
     if (cName != "") {
       cookie.style.display ="none"
       document.getElementsByClassName("isi")[0].classList.remove("cookies-active")
       document.getElementById("___gatsby").classList.add("cookie-bar-accepted");
     } else {
         cookie.style.display ="flex"
     }
 }

 getCookie = (cname) => {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(';');
   for(var i = 0; i <ca.length; i++){
     var c = ca[i];

     while (c.charAt(0) == ' '){
       c = c.substring(1);
     }
     if(c.indexOf(name) == 0){
       return c.substring(name.length, c.length);
     }

   }
   return ""
 }

 setCookie = (cname, cvalue, exdays) => {
   const cookie = document.querySelector('.cookies');
   var d = new Date();
        // console.log(d)
     d.setDate(d.getDate() + exdays);
        // console.log(d)
     var expires = "expires="+d.toUTCString();
     var  newCookie = cname + "=" + cvalue + ";" + expires + ";path=/"
     // console.log(newCookie)
     document.cookie = newCookie
 }

    setIsi = () => {
      // console.log("fired")
     var height =  window.getComputedStyle(document.getElementById("isi")).height
    height = height.substring(0,height.length - 2)
    height = parseInt(height)
    // console.log(height)
     if (window.innerWidth > 600){
       
    var bottomValue = (-1 * height) + 105
    // console.log(bottomValue)
    }
    else{
      bottomValue = (-1 * height) + 135
    }
    var distance = document.getElementById("isi").getBoundingClientRect().y
    this.setState({scrollTo: distance})
    document.getElementById("isi").setAttribute("style",`bottom:${bottomValue}px ; position: sticky`)
    // console.log("isi-set")
    }

    parseMobile = () => {
      if( window.innerWidth < 1000){
        this.setState({
          mobile:true
        })
      }
      else{
        this.setState({
          mobile:false
        })
      }
    }


    observe = () => {
      var element = document.getElementsByClassName("observer")[0]
      this.io.observe(element)
      // console.log("returned")
    }


    removeObserver = () => {
      var element = document.getElementsByClassName("observer")[0]
      this.io.unobserve(element)
      // console.log("removed")
    }




    isiSwitch = () =>{

        // console.log('switch triggered')
        const icon = document.querySelector('.expandISI');
        const ISIID = document.querySelector('#isi')
        const ISI   = document.querySelector('.isi')
        const COLLAPSABLE = document.querySelector('.collapsable')
        var isi = document.getElementById("isi")
        var windowHeight = window.innerHeight
        var isiTop = isi.getBoundingClientRect().top
        var isiHeight = window.getComputedStyle(isi).height

      isiHeight =  isiHeight.substring(0, isiHeight.length - 2)
      isiHeight = parseInt(isiHeight)

      if (isiHeight + isiTop < windowHeight){
        placeHolderHeight = isiHeight
        // console.log(isiHeight + isiTop)
        // console.log(windowHeight)
        ISIID.setAttribute("style","position:static")
        document.getElementsByClassName("expandISI")[0].setAttribute("style","display:none")

      }
      else if(windowHeight - isiTop <= placeHolderHeight) {
        // console.log(isiHeight)
        // console.log(isiTop)
        ISIID.setAttribute("style","position:sticky")
        document.getElementsByClassName("expandISI")[0].setAttribute("style","display:unset")


      }
    }






  expandISI = (e) => {
    // If key event is ENTER (13), or if its a MouseEvent (undefined) => ABORT PROCESS
    if(!(e.keyCode == undefined || e.keyCode == 13))  return;
    
    if(document.getElementById("isi-target-container").getBoundingClientRect().top > 1){
      document.getElementsByClassName("toggleISI")[0].classList.add("flip")
      document.getElementsByClassName("toggleISI")[0].setAttribute("aria-label", "Scroll to Top")
      document.getElementById("isi-target-container").scrollIntoView({ behavior: "smooth", block: "start" })
    }else{
     window.scrollTo(0,0)
     document.getElementsByClassName("toggleISI")[0].classList.remove("flip")
     document.getElementsByClassName("toggleISI")[0].setAttribute("aria-label", "Scroll to ISI")
    }
  }



    render(){
      var isi
      if (this.state.mobile === true){

      isi =  <div id="isi">
          <CookieNotification closeCookie={this.closeCookie}/>
          <div className="isi-target-addendum" id="isiTarget">&nbsp;</div>
            <div className="mobile-isi isi ">

              <h2 className="isi-header" tabIndex="0">
                INDICATIONS
                <span className="expandISI toggleISI" onClick={this.expandISI.bind(this)}></span>
              </h2>

              <h3 className='isi-title'>WHAT IS VASCEPA?</h3>

              <h3 className="isi-content">VASCEPA is a prescription medicine used:</h3>

              <div style={{height:"1px"}}  className="observer"></div>

              <div className="collapsable">
                <div className="isi-content">
                  <ul>
                    <li>along with certain medicines (statins) to reduce the risk of heart attack, stroke and certain types of heart issues requiring hospitalization in adults with heart (cardiovascular) disease, or diabetes and 2 or more additional risk factors for heart disease.</li>
                    <li>along with a low-fat and low-cholesterol diet to lower high levels of triglycerides (fats) in adults.</li>
                  </ul>
                  <p>It is not known if VASCEPA changes your risk of having inflammation of your pancreas (pancreatitis). <br/>It is not known if VASCEPA is safe and effective in children.</p>
                </div>

                <h2 className="isi-header" tabIndex="0" id="isi-scroll">IMPORTANT SAFETY INFORMATION</h2>
                <h3 className="isi-title">WHO SHOULD NOT TAKE VASCEPA?</h3>

                <div className="isi-content">
                  <ul>
                    <li>Do not take VASCEPA if you are allergic to icosapent ethyl or any of the ingredients in VASCEPA.</li>
                  </ul>
                </div>

                <h3 className="isi-title">
                  <span style={{textTransform:"uppercase"}}>What are the possible side effects of VASCEPA?</span><br/>
                  VASCEPA may cause serious side effects, including:
                </h3>
                <div className="isi-content">
                  <ul className="p-b-2em">
                    <li><span className="bolded-isi-content">Heart rhythm problems (atrial fibrillation and atrial flutter).</span> Heart rhythm problems which can be serious and cause hospitalization have happened in people who take VASCEPA, especially in people who have heart (cardiovascular) disease or diabetes with a risk factor for heart (cardiovascular) disease,     or who have had heart rhythm problems in the past. Tell your doctor if you get any symptoms of heart rhythm problems such as feeling as if your heart      is beating fast and irregular, lightheadedness, dizziness, shortness of breath, chest discomfort or you faint.</li>
                    <li><span className="bolded-isi-content">Possible allergic reactions if you are allergic to fish or shellfish.</span> Stop taking VASCEPA and tell your doctor right away or get emergency medical help if you have any signs or symptoms of an allergic reaction.</li>
                    <li><span className="bolded-isi-content">Bleeding.</span> Serious bleeding can happen in people who take VASCEPA. Your risk of bleeding may increase if you are also taking a blood thinner medicine.</li>
                  </ul>
                  If you have liver problems and are taking VASCEPA, your doctor should do blood tests during treatment.<br/>
                  The most common side effects of VASCEPA include:
                  <ul>
                    <li>Muscle and joint pain</li>
                    <li>Swelling of the hands, legs, or feet</li>
                    <li>Constipation</li>
                    <li>Gout</li>
                    <li>Heart rhythm problems (atrial fibrillation)</li>
                  </ul>
                  These are not all the possible side effects of VASCEPA. Call your doctor for medical advice about side effects. 
                    You may report adverse events (i.e. side effects) or product complaints by contacting AmarinConnect at <span style={{whiteSpace: "nowrap"}}>1-855-VASCEPA</span> <span style={{whiteSpace: "nowrap"}}>(1-855-827-2372)</span>, emailing <a href="mailto:AmarinConnect@Amarincorp.com">AmarinConnect@Amarincorp.com</a>, or calling the FDA at <span style={{whiteSpace: "nowrap"}}>1-800-FDA-1088.</span>
                  <br/><br/>
                  Tell your doctor if you take medicines that affect your blood clotting (anticoagulants or blood thinners).
                  <br/><br/>
                  For more information on VASCEPA, <a href="https://amarincorp.com/docs/Vascepa-PI.pdf" target="_blank">click here</a> to see the full Patient Information or call 1-855-VASCEPA (1-855-827-2372).
                </div>

                <br/>
                <br/>
              </div>

            </div>
         </div>

      }
      else{
        isi = <div id="isi">
          <CookieNotification closeCookie={this.closeCookie}/>

          <div className="isi-target-addendum" id="isiTarget">&nbsp;</div>
          <div  className="isi ">
            <div className="row">
              <div className="col-12 p-l-0">

              {/* FLOAT BLOCK */}
                <div className="col-6 float-right pb-4 pl-4 pr-0">
                  <h2 className="isi-header" tabIndex="0">
                    INDICATIONS
                    <span tabIndex="0" role="button" aria-label="Scroll to ISI" className="expandISI toggleISI" onKeyDown={this.expandISI.bind(this)} onClick={this.expandISI.bind(this)}></span>
                  </h2>

                  <h3 className='isi-title'>
                    WHAT IS VASCEPA?
                  </h3>

                  <div className="isi-content">
                    VASCEPA is a prescription medicine used:
                  </div>

                  <div style={{height:"1px"}}  className="observer"></div>

                  <div className="isi-content">
                    <ul>
                      <li>
                        along with certain medicines (statins) to reduce the risk of heart attack, stroke and certain types of heart issues requiring hospitalization in adults with heart (cardiovascular) disease, or diabetes and 2 or more additional risk factors for heart disease.
                      </li>
                      <li>
                        along with a low-fat and low-cholesterol diet to lower high levels of triglycerides (fats) in adults.

                      </li>
                    </ul>
                    <p>
                      It is not known if VASCEPA changes your risk of having inflammation of your pancreas (pancreatitis).
                      <br/>It is not known if VASCEPA is safe and effective in children.
                    </p>
                  </div>

                </div>
              {/* FLOAT BLOCK */}

                <h2 className="isi-header fit-content" tabIndex="0" id="isi-scroll">
                  IMPORTANT SAFETY INFORMATION
                </h2>
                <h3 className="isi-title">
                  WHO SHOULD NOT TAKE VASCEPA?
                </h3>

                <div className="isi-content">
                  <ul>
                    <li>
                      Do not take VASCEPA if you are allergic to icosapent ethyl or any of the ingredients in VASCEPA.
                    </li>
                  </ul>
                </div>

                <h3 className="isi-title">
                  <span style={{textTransform:"uppercase"}}>What are the possible side effects of VASCEPA?</span><br/>
                  VASCEPA may cause serious side effects, including:
                </h3>
                <div className="isi-content">
                  <ul className="p-b-2em">
                    <li>
                      <h4 className="bolded-isi-content">Heart rhythm problems (atrial fibrillation and atrial flutter).</h4> Heart rhythm problems which can be serious and cause hospitalization have happened in people who take VASCEPA, especially in people who have heart (cardiovascular) disease or diabetes with a risk factor for heart (cardiovascular) disease, or who have had heart rhythm problems in the past. Tell your doctor if you get any symptoms of heart rhythm problems such as feeling as if your heart is beating fast and irregular, lightheadedness, dizziness, shortness of breath, chest discomfort or you faint.
                    </li>
                    <li className="mt-2">
                      <h4 className="bolded-isi-content">Possible allergic reactions if you are allergic to fish or shellfish.</h4> Stop taking VASCEPA and tell your doctor right away or get emergency medical help if you have any signs or symptoms of an allergic reaction.
                    </li>
                    <li>
                      <h4 className="bolded-isi-content">Bleeding.</h4> Serious bleeding can happen in people who take VASCEPA. Your risk of bleeding may increase if you are also taking a blood thinner medicine.
                    </li>
                  </ul>
                  If you have liver problems and are taking VASCEPA, your doctor should do blood tests during treatment.<br/>
                    The most common side effects of VASCEPA include:
                    <ul>
                      <li>
                        Muscle and joint pain
                      </li>
                      <li>
                        Swelling of the hands, legs, or feet
                      </li>
                      <li>
                        Constipation
                      </li>
                      <li>
                        Gout
                      </li>
                      <li>
                        Heart rhythm problems (atrial fibrillation)
                      </li>
                    </ul>
                    These are not all the possible side effects of VASCEPA. Call your doctor for medical advice about side effects. 
                    You may report adverse events (i.e. side effects) or product complaints by contacting AmarinConnect at <span style={{whiteSpace: "nowrap"}}>1-855-VASCEPA</span> <span style={{whiteSpace: "nowrap"}}>(1-855-827-2372)</span>, emailing <a href="mailto:AmarinConnect@Amarincorp.com">AmarinConnect@Amarincorp.com</a>, or calling the FDA at <span style={{whiteSpace: "nowrap"}}>1-800-FDA-1088.</span>
                      <br/><br/>
                      Tell your doctor if you take medicines that affect your blood clotting (anticoagulants or blood thinners).
                      <br/><br/>
                      For more information on VASCEPA, <a href="https://amarincorp.com/docs/Vascepa-PI.pdf" target="_blank">click here</a> to see the full Patient Information or call 1-855-VASCEPA (1-855-827-2372).
                </div>
              </div>

            </div>








                  {/*<div className="isi-content">
                    <br/>
                          *In a clinical study, patients treated with VASCEPA had fewer cardiovascular events (17.2%) compared to those who took placebo (22%).
                  </div>*/}

          </div>
         </div>
      }



        return(
          isi
        )
    }

  }
