/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Savings from "../components/savings/savings"
import SignUpForm from "../components/form/form"

import Header from "./header/Header"
import Footer from "./footer/Footer"
import ISI from "./isi/ISI"
import $ from "jquery/dist/jquery.js"
import "bootstrap/dist/css/bootstrap.css"
import CookieNotification from "./isi/CookieNotification"
import LeavingSiteModal from "./modal/LeavingSiteModal"
import RARF_modal from "./modal/RarfModal"
// import Chatbox from "../components/chatbox/chatbox"

// import 'bootstrap/dist/js/bootstrap.js';
// import "./layout.css"

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: "none",
      formDisplay: "none",
      modal: 'null',
    }
    //console.log(props)
  }

  componentDidMount() {
    if (document.getElementsByClassName("sign-up-cta")[0] != undefined) {
      document
        .getElementsByClassName("sign-up-cta")[0]
        .addEventListener("click", this.handleOpenLink)
      document
        .getElementsByClassName("sign-up-cta")[0]
        .addEventListener("keydown", this.handleOpenLink)
    }
    document.addEventListener("keydown", this.screenshotMode, false)
  }
 


  handleOpenLink = event => {
    event.preventDefault()
    this.handleOpen(event)
  }

  handleOpen = (event) => {
    if(event.key != "Tab" ){
    window.scrollTo(0,0);
    this.setState({
      formDisplay: "inline-flex",
    })
    document.body.setAttribute("style", "overflow:hidden")
    }
    document.getElementsByClassName("menuClose")[0].focus()
  }

  //add screenshot mode to the body for screenshot purpose only
  screenshotMode = (e) => {
    if(e.ctrlKey && e.key === "l") {
      const element = document.querySelector('body');
      element.classList.toggle('screenshot-mode');
    }
  }

  handleClose = (event) => {
    if(event.key != "Tab" ){
    this.setState({
      formDisplay: "none",
    })
    document.body.setAttribute("style", "overflow:unset")
    }
  }

  goModal = (targ) => {
    this.setState(() => ({ modal: targ }))
 }

 modalClosed = () => {
   this.setState(() => ({ modal: null }))
 }

  render() {
    return (
      <div>
        <Header handleOpen={this.handleOpen} />
        
        <div className={`justify-content-center main-container ${this.props.customClass}`}>
          <main>
            {this.props.children}
            
          </main>
          {/* <Savings positionTarget=".home-page-carousel-outer" positionTargetOffset=".alert-danger" /> */}
          {/* <Chatbox /> */}
          
        </div>

        <SignUpForm
          formDisplay={this.state.formDisplay}
          menuClose={this.handleClose}
        />
        <LeavingSiteModal />
        <RARF_modal/>
        {/*<div
          style={{ width: "100%", height: "1px", marginTop: "10px", paddingTop:"10px" }}
        >
        </div>*/}
        <div
          id="isi-target-container"
          style={{ width: "100%", height: "1px"}}
        ></div>
        <ISI />
        <div className="isi" dangerouslySetInnerHTML={{__html: this.props.footnote}}></div>
        <br/>
        <br/>
        <Footer />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
