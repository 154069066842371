// NavList.js
import React from "react";
import NavList from "./NavList";

class NavContainer extends React.Component {
    constructor() {
        super();
        this.state = { 
            navlist:['a','b'],
            navs: []

        }
    }

    componentDidMount() {}

    componentDidUpdate(){}

    render() {
        return <NavList list={this.state.navlist} />;
    }
}

export default NavContainer