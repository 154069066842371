import React from "react"
import { Link } from "gatsby"
import "./nav-list.css"
import vascepaLogo from "../../images/mobile-nav-logo-vascepa.svg"
import homeLogo from "../../images/icon/home.svg"
import DropdownTriangle from "../../images/icon/arrow"
import DesktopDropdownTriangle from "../../images/icon/arrow.svg"
var home = "/"

var cardiovascularrisk = "/cardiovascular-risk/"
var benefits = "/benefits/"
var patientstories ="/benefits/patient-stories/"
var tips = "/cardiovascular-risk/healthy-heart-tips/"
var talkingwithyourdoctor = "/cardiovascular-risk/talking-with-your-doctor/"
var protectagainstanotherheartattackorstroke = "/cardiovascular-risk/protect-against-another-heart-attack-or-stroke/"
var diabetesandheartdisease = "/cardiovascular-risk/diabetes-and-heart-disease/"
var signupforsupport = "/getting-started/sign-up/"
var safetysideeffects="/about-vascepa/safety-and-side-effects/"
var quiz = "/cardiovascular-risk/quiz/"
var clinicaltrialresults = "/benefits/clinical-trial-results/"
var howvascepaworks = "/about-vascepa/how-it-works/"
var howtotake = "/getting-started/dosing/"
var helpfulResources = "/getting-started/helpful-resources/"
var history ="/about-vascepa/history/"
var gettingstarted = "/getting-started/"
var about = "/about-vascepa/"
var savingscard = "/getting-started/vascepa-savings/"


    export default class NavList extends React.Component {

        constructor(props) {
           super(props);
           this.state ={
             risk: "",
             benefits: "",
             about: "",
             gettingstarted : ""
           };
        }
        componentDidMount(){
          this.currentState()
          document.addEventListener("keydown", this.keyClearMenu
          )
          // this.handleSecondHover()
        }
        componentWillUnmount(){
          document.removeEventListener("keydown", this.keyClearMenu)
        }

        handlehover = (event) => {
          event.target.classList.contains("risk") ? this.setState({risk : "show",    benefits: "", about: "", gettingstarted : "" }) : this.setState({})
          event.target.classList.contains("benefits") ? this.setState({benefits : "show", risk: "", about: "", gettingstarted : "" }) : this.setState({})
          event.target.classList.contains("about") ? this.setState({about : "show", benefits: "", risk: "", gettingstarted : "" }) : this.setState({})
          event.target.classList.contains("gettingstarted") ? this.setState({gettingstarted : "show", benefits: "", about: "", risk : "" }) : this.setState({})
        }

        keyClearMenu = (e) => {
          if (e.key == "Escape"){
            this.clearMenu()
          }
          if (document.activeElement.id === "signupforsupport"){
            this.clearMenu()
          }
        }

        clearMenu = () => {
          this.setState({
            risk: "",
            benefits: "",
            about: "",
            gettingstarted : ""
          })
          // console.log("cleared")
        }


          currentState = () => {

            var activeDesktopElement = document.getElementsByClassName("desk-active-nav")[0]
            var activeMobileElement = document.getElementsByClassName("mobile-active-nav")[0]

            if (activeDesktopElement) {
              activeDesktopElement.parentElement.setAttribute("style","background-image: linear-gradient(250deg, rgba(29, 53, 94, 0.35), rgba(0, 0, 0, 0.05) 78%, rgba(0, 0, 0, 0.19) 52%, rgba(0, 0, 0, 0.05) 26%, rgba(29, 53, 94, 0.35));;")
              activeDesktopElement.parentElement.parentElement.parentElement.setAttribute("style", "background-image: linear-gradient(250deg, rgba(29, 53, 94, 0.35), rgba(0, 0, 0, 0.05) 78%, rgba(0, 0, 0, 0.19) 52%, rgba(0, 0, 0, 0.05) 26%, rgba(29, 53, 94, 0.35));");
            }
            if (activeMobileElement) {
              activeMobileElement.setAttribute("style","background-image: linear-gradient(250deg, rgba(29, 53, 94, 0.35), rgba(0, 0, 0, 0.05) 78%, rgba(0, 0, 0, 0.19) 52%, rgba(0, 0, 0, 0.05) 26%, rgba(29, 53, 94, 0.35));display: block; color: #1d355e; font-size: 16px; font-weight: 900;")
              activeMobileElement.parentElement.parentElement.parentElement.setAttribute("style", "background-image: linear-gradient(250deg, rgba(29, 53, 94, 0.35), rgba(0, 0, 0, 0.05) 78%, rgba(0, 0, 0, 0.19) 52%, rgba(0, 0, 0, 0.05) 26%, rgba(29, 53, 94, 0.35));");
            }
          }

          // handleSecondHover = () => {
          //   var activeElement = document.getElementsByClassName("desk-active-nav")[0]
          //   console.log("now happening")
          //   activeElement.style.background ='';
          //   }


        mobileNavActive = (event) => {
          // event.preventDefault();
          if(document.getElementsByClassName('mobile-active-nav')[0] != null) {
            var prev = document.getElementsByClassName('mobile-active-nav')[0];
          }
          var el = event.target;
          var dest = event.target.href;
          var ggparent = el.parentElement.parentElement.parentElement;
          var a = ggparent.childNodes[0];
          if(prev != null) {
            prev.setAttribute('style','font-weight: 500;color: #4D4D4D;background-color: transparent;border-top: 0px solid white;min-height: 55px;')
          }
          a.setAttribute('aria-expanded', 'true');
          a.nextSibling.classList.add('show');
          el.classList.add('mobile-active-temp');
          // setTimeout(function(){
          //   window.location.assign(dest);
          // }, 300);
        }



        render(){
            return(
              <div className="outer-navbar">
                <div className="mobile-navbar" >
                  <nav className="navbar navbar-expand-xl navbar-light">
                      <Link className="btn-primary no-border" id="savingscard" to={savingscard}>
                        SAVINGS <br/>
                        CARD
                      </Link>

                      <h1 className="navbar-brand">
                        <Link to="/"><img alt="Homepage Button" src={vascepaLogo}/></Link>
                      </h1>
                      
                      <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

                          <div className="navbar-toggler-icon-container">
                            <span className="menu-text-mobile">MENU</span>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                          </div>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarNav">
                          <ul className="navbar-nav">
                              <li className="nav-item dropdown" >
                                  <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <span className="cardio-risk">What's Your <br/>Cardiovascular Risk?</span>
                                      <DropdownTriangle className="dropdown-triangle" alt=""/>
                                  </a>
                                  

                                  <div className="dropdown-menu"  aria-labelledby="navbarDropdown">
                                      <div className="selection" >
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="cardiovascular-risk" to={cardiovascularrisk}>What's Your Cardiovascular Risk?</Link>
                                      </div>
                                      <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="quiz" to={quiz}>QUIZ: Is Your Heart At Risk</Link>
                                      </div>
                                      <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="quiz" to={protectagainstanotherheartattackorstroke}>Protect Against Another Heart Attack or Stroke</Link>
                                      </div>

                                      <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="talking-with-your-doctor" to={talkingwithyourdoctor}>Talking With Your Doctor</Link>
                                      </div>
                                      <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="tips" to={tips}>Heart Health Tips & Guidance</Link>
                                      </div>
                                      <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="diabetes-and-heart-disease" to={diabetesandheartdisease}>Diabetes & Cardiovascular&nbsp;Risk</Link>
                                      </div>
                                  </div>
                              </li>

                              <li className="nav-item dropdown">
                                  <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    VASCEPA Benefits
                                    <DropdownTriangle className="dropdown-triangle" alt=""/>
                                  </a>
                                  
                                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="benefits" to={benefits}>VASCEPA Benefits</Link>
                                    </div>

                                    <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="clinical-trial-results" to={clinicaltrialresults}>Proven Cardiovascular Protection</Link>
                                    </div>
                                    {/*<div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="patient-stories" to={patientstories}>True VASCEPA Stories</Link>
                                    </div>*/}
                                  </div>
                              </li>

                              <li className="nav-item dropdown">
                                  <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    About VASCEPA
                                    <DropdownTriangle className="dropdown-triangle" alt=""/>
                                  </a>
                                  

                                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="about" to={about}>About VASCEPA</Link>
                                    </div>
                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="howvascepaworks" to={howvascepaworks}>How VASCEPA Works</Link>
                                    </div>
                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="safetysideeffects" to={safetysideeffects}>Safety & Side Effects</Link>
                                    </div>
                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="history" to={history}>History of VASCEPA and Amarin</Link>

                                    </div>
                                  </div>
                              </li>

                              <li className="nav-item dropdown">
                                  <a className="nav-link dropdown-toggle" href="/" actclassname="mobile-active-nav" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Get Started on VASCEPA
                                    <DropdownTriangle className="dropdown-triangle" alt=""/>
                                  </a>
                                  
                                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="gettingstarted" to={gettingstarted}>Get Started on VASCEPA</Link>

                                    </div>

                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="helpfulResources" to={helpfulResources}>Helpful Resources</Link>

                                    </div>

                                    <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="howtotake" to={howtotake}>How To Take VASCEPA</Link>

                                    </div>
                                    <div className="selection">
                                        <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="savingscard" to={savingscard}>VASCEPA Savings</Link>
                                    </div>
                                    {/* <div className="selection">
                                      <Link onTouchStart={this.mobileNavActive} className="selection-interior" activeClassName="mobile-active-nav" id="signupforsupport" to={signupforsupport}>Sign Up For Support & Info</Link>

                                    </div> */}
                                  </div>
                              </li>
                          </ul>
                      </div>

                  </nav>
                  <div className="Rainbow wrapper animate">
                  </div>
                </div>
                <div  className="desktop-navbar">
                  <nav   onMouseLeave={this.clearMenu} className="navbar navbar-expand-xl navbar-light">


                      <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

                          <div className="navbar-toggler-icon-container">
                            <span className="menu-text-mobile">MENU</span>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                          </div>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarNav" >

                          <ul className="navbar-nav row align-items-center justify-content-center" >

                              <h1 className="navbar-brand col-2 order-4 " tabIndex="-1">
                                <Link tabIndex="0" to="/" className="blue-outline"><img alt="Vascepa® (icosapent ethyl)" src={vascepaLogo}/></Link>
                              </h1>
                            
                              <Link to={home} className="home-button order-1 blue-outline "><img alt="Homepage Button" src={homeLogo}></img> </Link>

                              <li className={"nav-item dropdown col-2 coll-offset-1 order-2 " + this.state.risk}>
                                  <Link role="button" to={cardiovascularrisk} onMouseEnter={this.handlehover} onFocus={this.handlehover}  className="risk nav-link dropdown-toggle"   href="/" id="navbarDropdown">
                                      <span className="nav-span ">What's Your <br/>Cardiovascular Risk?</span>

                                  </Link>
                                  <img src={DesktopDropdownTriangle} className="dropdown-triangle" alt=""/>


                                  <div onMouseEnter={this.handlehover} onFocus={this.handlehover} className={"risk dropdown-menu " + this.state.risk} aria-labelledby="navbarDropdown" >
                                    <div className="selection">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav" to={cardiovascularrisk}>  What's Your Cardiovascular Risk?</Link>
                                    </div>
                                      <div className="selection">
                                        <Link role="button" className="nav-link " activeClassName="desk-active-nav" to={quiz}>  QUIZ: Is Your Heart At Risk</Link>
                                      </div>
                                      <div className="selection">
                                        <Link role="button" className="nav-link " activeClassName="desk-active-nav" to={protectagainstanotherheartattackorstroke}>  Protect Against Another Heart Attack or Stroke</Link>
                                      </div>

                                      <div className="selection">
                                        <Link role="button" className=" nav-link " activeClassName="desk-active-nav" to={talkingwithyourdoctor}> Talking With Your Doctor</Link>
                                      </div>
                                      <div className="selection">
                                      <Link role="button" className=" nav-link " activeClassName="desk-active-nav" to={tips}>Heart Health Tips & Guidance</Link>
                                      </div>
                                      <div className="selection">
                                        <Link role="button" className=" nav-link " activeClassName="desk-active-nav" to={diabetesandheartdisease}> Diabetes & Cardiovascular&nbsp;Risk</Link>
                                      </div>
                                  </div>
                              </li>

                              <li className={"nav-item dropdown col-2 order-3 " + this.state.benefits}>
                                  <Link role="button" to={benefits} onMouseEnter={this.handlehover} onFocus={this.handlehover} className="nav-link dropdown-toggle benefits"   href="/" >
                                    <span className="nav-span">VASCEPA<br/> Benefits</span>
                                  </Link>
                                  <img src={DesktopDropdownTriangle} className="dropdown-triangle" alt=""/>

                                  <div onMouseEnter={this.handlehover} onFocus={this.handlehover} className={"dropdown-menu benefits " + this.state.benefits} aria-labelledby="navbarDropdown">
                                    <div className="selection">
                                        <Link role="button" className="nav-link " activeClassName="desk-active-nav"to={benefits}>VASCEPA Benefits</Link>
                                    </div>
                                    <div className="selection">
                                        <Link role="button" className="nav-link " activeClassName="desk-active-nav"to={clinicaltrialresults}>Proven Cardiovascular Protection</Link>
                                    </div>
                                    {/*
                                    <div className="selection">
                                        <Link className="nav-link " activeClassName="desk-active-nav" to={patientstories}>True VASCEPA Stories</Link>
                                    </div>
                                    */}
                                  </div>
                              </li>

                              <li className={"nav-item dropdown col-2 order-5 " + this.state.about}>
                                  <Link role="button" to={about} onMouseEnter={this.handlehover} onFocus={this.handlehover} className="nav-link dropdown-toggle about "  >
                                    <span className="nav-span">About <br/> VASCEPA</span>
                                  </Link>
                                  <img src={DesktopDropdownTriangle} className="dropdown-triangle" alt=""/>

                                  <div onMouseEnter={this.handlehover} onFocus={this.handlehover}  className={"dropdown-menu about " + this.state.about} aria-labelledby="navbarDropdown">
                                    <div className="selection">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={about}>About VASCEPA</Link>
                                    </div>
                                    <div className="selection">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={howvascepaworks}>How VASCEPA Works</Link>
                                    </div>
                                    <div className="selection">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav" to={safetysideeffects}>Safety & Side Effects</Link>
                                    </div>
                                    <div className="selection">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={history}>History of VASCEPA and Amarin</Link>
                                    </div>
                                  </div>
                              </li>

                              <li className={"nav-item dropdown col-2 order-6 " + this.state.gettingstarted}>
                                  <Link role="button" to={gettingstarted} onMouseEnter={this.handlehover} onFocus={this.handlehover} className="nav-link dropdown-toggle gettingstarted " >
                                    <span className="nav-span">Getting Started<br/> on VASCEPA</span>
                                  </Link>
                                  <img src={DesktopDropdownTriangle} className="dropdown-triangle" alt=""/>

                                  <div onMouseEnter={this.handlehover} onFocus={this.handlehover} className={"dropdown-menu gettingstarted  " + this.state.gettingstarted} aria-labelledby="navbarDropdown">
                                    <div className="selection ">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={gettingstarted}>Getting Started on VASCEPA</Link>

                                    </div>
                                   
                                    <div className="selection ">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={helpfulResources}>Helpful Resources</Link>

                                    </div>

                                    <div className="selection ">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={howtotake}>How To Take VASCEPA</Link>

                                    </div>
                                    <div className="selection">
                                        <Link role="button" className="nav-link " activeClassName="desk-active-nav"  to={savingscard}>VASCEPA Savings</Link>

                                    </div>
                                    {/* <div className="selection">
                                      <Link role="button" className="nav-link " activeClassName="desk-active-nav" id="signupforsupport"  to={signupforsupport}>Sign Up for Support & Info</Link>

                                    </div> */}
                                  </div>
                              </li>
                          </ul>

                      </div>


                  </nav>
                  <div className="rainbow-container " tabIndex="-1">
                  <div className="Rainbow wrapper animate" tabIndex="-1">
                  </div>
                  </div>

                </div>
              </div>
            )
        }

      }
