import React from "react"
import { Link } from "gatsby"
import "./form.css"
import menuClose from "../../images/form/menuClose.svg"
import backgroundMesh from "../../images/form/backgroundMesh.png"
import $ from "jquery"

export default class SignUpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      firstname: "",
      lastname: "",
      email: "",
      engLevel: "",
      mobileOpt: false,
      therapyFail: false,
      focused: false
    }
  }
  componentDidMount() {
  }


  componentDidUpdate() {
    const formRadioHeight = document.querySelector(".radiohide").offsetHeight
    const formContainer = document.querySelector(".form-inner")
    formContainer.style.height = formRadioHeight + 130 + "px"

    if(this.props.formDisplay === "inline-flex"){
      if(this.state.focused === false){
        this.setState({focused: true})
        formContainer.focus()
      }
    }else{
      if(this.state.focused === true){
        this.setState({focused: false})
      }
    }

  }

  handleState = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  triggerClick = e => {
    var key = e.keyCode || e.which
    if(key === 32){
      e.target.click();
    }
  }

  toggleCard = e => {
    this.setState({
      mobileOpt: e.target.checked,
    })
  }

  validateForm = event => {
    event.preventDefault()
    var firstName = this.validateFirstName()
    var lastName = this.validateLastName()
    var email = this.validateEmail()
    var therapy = this.validateTherapy()
    var phone = this.validatePhone()

    if (firstName && lastName && email && therapy && phone) {
      this.submitSignUpForm(function(err, res) {
        if (err) {
          // console.log(err)
        } else {
          // console.log(res)
        }
      })

      this.thankyou();
      document.querySelector(".form-inner").focus();

    } else {
      event.preventDefault()
      window.scrollTo(0, 0)
      document.getElementsByClassName("form-outer")[0].scrollTo(0, 0)

      var nextErrorField = document.querySelector('.valid-fail')
      
      if(nextErrorField){
          if(nextErrorField.tagName === "DIV"){
            nextErrorField.focus();
          }else{
            nextErrorField.focus();
          }
      }
    }
  }

  validateEmail = () => {
    var node = document.getElementsByClassName("email")[0];

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(this.state.email)){
      node.classList.remove("valid-fail");
      node.setAttribute("aria-invalid", "false");
      this.clearError("email", "");
      return true
    }else {
      node.classList.add("valid-fail");
      node.setAttribute("aria-invalid", "true");
      this.showError("email", "Please enter a valid email address");
      node.value = ""; 
      return false
    }
  }

  validatePhone = () => {
    var node = document.getElementsByClassName("phone")[0];
    var phoneno = /^\d{10}$/

    if (this.state.phone != "" || this.state.mobileOpt === true) {
      if (this.state.phone.match(phoneno)) {
        node.classList.remove("valid-fail");
        node.setAttribute("aria-invalid", "false");
        this.clearError("phone", "");
        return true

      } else {
        node.classList.add("valid-fail");
        node.value = ""; 
        node.setAttribute("aria-invalid", "true");
        this.showError("phone", "Please enter a valid cell phone number");
        this.setState({"phone": ""})
        return false
      }

    }else {
      node.classList.remove("valid-fail")
      node.setAttribute("aria-invalid", "false");
      return true
    }
  }

  validateFirstName = () => {
    var node = document.getElementsByClassName("fname")[0]
    if(this.state.firstname != "") {
      node.classList.remove("valid-fail");
      node.setAttribute("aria-invalid", "false");
      this.clearError("fname", "");
      return true;
    }else{
      node.classList.add("valid-fail");
      node.setAttribute("aria-invalid", "true");
      this.showError("fname", "Please enter a first name");
      return false
    }
  }

  validateLastName = () => {
    var node = document.getElementsByClassName("lname")[0]
    if(this.state.lastname != "") {
      node.classList.remove("valid-fail")
      node.setAttribute("aria-invalid", "false");
      this.clearError("lname", "");
      return true
    }else{
      node.classList.add("valid-fail");
      node.setAttribute("aria-invalid", "true");
      this.showError("lname", "Please enter a last name");
      return false
    }
  }

  validateTherapy = () => {
    if (this.state.engLevel != "") {
      this.setState({therapyFail: false});
      document.querySelector("#treatments_list").classList.remove("valid-fail");
      document.querySelector("#treatments_list").setAttribute("aria-invalid", "false");
      return true;

    } else {
      this.setState({therapyFail: true});
      document.querySelector("#treatments_list").classList.add("valid-fail");
      document.querySelector("#treatments_list").setAttribute("aria-invalid", "true");
      return false;
    }
  }

  showError = (label, errorMsg) => {
    document.querySelector(`#label_${label}`).classList.add("sr-only");
    document.querySelector(`#error_${label}`).classList.remove("sr-only");
    document.querySelector(`#error_${label}`).textContent = errorMsg;
  }

  clearError = (label) => {
    document.querySelector(`#label_${label}`).classList.remove("sr-only");
    document.querySelector(`#error_${label}`).classList.add("sr-only");
    document.querySelector(`#error_${label}`).textContent = "";
  }


  submitSignUpForm = callback => {
    var payload = {
      mobileID: this.state.phone,
      optional1: this.state.address,
      optional2: this.state.city,
      optional3: this.state.state,
      optional4: this.state.zipcode,
      firstName: this.state.firstname,
      lastName: this.state.lastname,
      email: this.state.email,
      engLevel: this.state.engLevel,
      endPointTriggerChannel: 'web'
    }

    $.ajax({
      url: "/backend/",
      data: payload,
      type: "POST",
      crossDomain: true,
      complete: function(resp) {
        // console.log("complete")
        callback(null, resp)
      },
      success: function(resp) {
        if (resp.status == 200) {
          // console.log("form submitted")
          // console.log(resp)
        } else if (resp.status == 500) {
          // console.log("err")
          // console.log(resp)
        } else {
          // console.log("else-triggered")
          // console.log(resp)
        }
      },
      error: function(resp) {
        // console.log(resp)
        if (resp.status == 200) {
          // console.log("form submitted")
          callback(null, resp)
        } else if (resp.status == 500) {
          // console.log("err")
          callback(resp)
        }
      },
    })
    if (this.state.mobileOpt) {
      payload.endPointTriggerChannel = 'sms';
      $.ajax({
        url: "/backend/",
        data: payload,
        type: "POST",
        crossDomain: true,
        complete: function(resp) {
          // console.log("complete")
          callback(null, resp)
        },
        success: function(resp) {
          if (resp.status == 200) {
            // console.log("form submitted")
            // console.log(resp)
          } else if (resp.status == 500) {
            // console.log("err")
            // console.log(resp)
          } else {
            // console.log("else-triggered")
            // console.log(resp)
          }
        },
        error: function(resp) {
          // console.log(resp)
          if (resp.status == 200) {
            // console.log("form submitted")
            callback(null, resp)
          } else if (resp.status == 500) {
            // console.log("err")
            callback(resp)
          }
        },
      })
    }
  }

  thankyou = () => {

    document.querySelector(".form-inner").style.minHeight = "460px";
    document.querySelector(".form-inner").style.height = "460px";
    document.getElementsByClassName("sign-up-headline")[0].innerHTML = "Thank you for registering for The VASCEPA Clear Path Program!";
    document.getElementsByClassName("sign-up-subhead")[0].innerHTML = "Emails with useful info and Clear Path Tips will be arriving soon.";
    document.getElementsByClassName("sign-up-content")[0].innerHTML = "";
    document.getElementsByClassName("sign-up-fields")[0].innerHTML = "";
    document.getElementsByClassName("menuClose")[0].classList.add("thank-you-close");
  }

  handleClose = (e) => {
    this.props.menuClose(e);
}

  render() {
    return (
      <div style={{ display: this.props.formDisplay }} className="form-outer">
        <div className="form-inner" tabIndex="0">
          <div className="form-background" />
         
          <img
            onClick={this.handleClose}
            onKeyDown={this.handleClose}
            className="menuClose"
            src={menuClose}
            role="button"
            aria-label="Close modal"
            tabIndex="0"
          />

          <div className="content-container">
            <form className="radiohide">
              <h2 className="sign-up-headline">
                Register now for The
                <br /> VASCEPA Clear Path Program
              </h2>
              <h3 className="sign-up-subhead" tabIndex="0">
                Sign up below for exclusive access to heart health information
                and all the latest news from VASCEPA.
              </h3>
              <p className="sign-up-content">
                By registering at this site, you certify that you reside in the
                United States and are at least 18 years of age. Amarin Pharma,
                Inc. understands that your personal and health information is
                private. The information you provide will only be used by Amarin
                Pharma, Inc. and parties acting on its behalf to send you the
                materials you requested and other helpful information and
                updates on VASCEPA for heart health, as well as related
                information and services. We will protect your privacy in
                accordance with our{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline", color: "#4d4d4d" }}
                  href="https://amarincorp.com/privacy_policy.html  "
                >
                  Privacy Policy
                </a>
                .
              </p>
              <div className="sign-up-fields">
                
                <input
                  onChange={this.handleState}
                  placeholder="First name"
                  className="sign-up-input fname form-control"
                  id="validationDefault01"
                  type="text"
                  name="firstname"
                  aria-invalid="false"
                  aria-describedby="error_fname"
                />
                <label id="label_fname" className="simple" htmlFor="validationDefault01">*First name</label>
                <span id="error_fname" role="alert" className="error sr-only" aria-live="polite"></span>

                <input
                  onChange={this.handleState}
                  placeholder="Last name"
                  className="sign-up-input lname form-control"
                  id="validationDefault02"
                  type="text"
                  name="lastname"
                  aria-invalid="false"
                  aria-describedby="error_lname"
                />
                <label id="label_lname" className="simple" htmlFor="validationDefault02">*Last name</label>
                <span id="error_lname" role="alert" className="error sr-only" aria-live="polite"></span>
                

                <input
                  onChange={this.handleState}
                  placeholder="Email"
                  className="sign-up-input email form-control"
                  id="validationDefault03"
                  type="text"
                  name="email"
                  aria-invalid="false"
                  aria-describedby="error_email"
                />
                <label id="label_email" className="simple" htmlFor="validationDefault03">*Email</label>
                <span id="error_email" role="alert" className="error sr-only" aria-live="polite"></span>
                

                <h3 id="treatments_heading" style={{ fontWeight: "500" }} className="sign-up-subhead">
                  *Current heart health treatment (select one)
                </h3>

                <span aria-live="polite" role="alert">
                    {this.state.therapyFail ? <p className="invis therapy-fail">Please select a treatment</p> : null}
                  </span>

                <div id="treatments_list" role="group" aria-labelledby="treatments_heading" tabIndex="0">
                  

                  <input
                    onChange={this.handleState}
                    name="engLevel"
                    type="radio"
                    id="value1"
                    value="1"
                    tabIndex="-1"
                  />
                  <label onKeyPress={this.triggerClick} tabIndex="0" htmlFor="value1">Not on a heart health treatment</label>

                  <input
                    onChange={this.handleState}
                    name="engLevel"
                    type="radio"
                    id="value2"
                    value="2"
                    tabIndex="-1"
                  />
                  <label onKeyPress={this.triggerClick} tabIndex="0" htmlFor="value2">Taking a dietary supplement such as fish oil for heart health</label>

                  <input
                    onChange={this.handleState}
                    name="engLevel"
                    type="radio"
                    id="value3"
                    value="3"
                    tabIndex="-1"
                  />
                  <label onKeyPress={this.triggerClick} tabIndex="0" htmlFor="value3">Taking a prescription drug for heart health</label>

                  <input
                    onChange={this.handleState}
                    name="engLevel"
                    type="radio"
                    id="value4"
                    value="4"
                    tabIndex="-1"
                  />
                  <label className="mb-0" onKeyPress={this.triggerClick} tabIndex="0" htmlFor="value4">Other</label>
                </div>

                <div role="group">
                  <input
                    onChange={this.handleState}
                    placeholder="Address"
                    className="sign-up-input addres sign-up-space"
                    type="text"
                    name="address"
                    id="address"
                  />
                  <label className="simple" htmlFor="address">Address (optional)</label>

                  <input
                    onChange={this.handleState}
                    placeholder="City"
                    className="sign-up-input city"
                    type="text"
                    name="city"
                    id="city"
                  />
                  <label className="simple" htmlFor="city">City (optional)</label>

                </div>

                <div role="group">

                  <input
                    onChange={this.handleState}
                    placeholder="State"
                    className="sign-up-input state"
                    type="text"
                    name="state"
                    id="state"
                  />
                  <label className="simple" htmlFor="state">State (optional)</label>

                  <input
                    onChange={this.handleState}
                    placeholder="ZIP"
                    className="sign-up-input zip"
                    type="text"
                    name="zipcode"
                    id="zipcode"
                  />
                  <label className="simple" htmlFor="zipcode">ZIP (optional)</label>

                </div>

                <input
                  onChange={this.handleState}
                  placeholder="Cell phone"
                  className="sign-up-input phone"
                  type="text"
                  name="phone"
                  id="phone"
                  aria-invalid="false"
                  aria-describedby="error_phone"
                />
                <label id="label_phone" className="simple" htmlFor="phone">Cell phone (optional)</label>
                <span id="error_phone" role="alert" className="error sr-only" aria-live="polite"></span>


                <p className="red-required-prompt">(*indicates required fields)</p>
                <div style={{ marginTop: "20px" }}>
                  <label
                    style={{
                      marginBottom: "0px",
                      background: "transparent",
                      border: "none",
                      paddingLeft: "0px",
                      marginLeft: "-10px",
                    }}
                    className="label-req"
                  >
                    <br />
                    <input
                      className="hccCheckbox"
                      onClick={this.toggleCard}
                      type="checkbox"
                    />

                    <span className="sign-up-content">
                      {" "}
                      I want to receive a savings eCard and messaging related to
                      my prescription via text.
                    </span>
                  </label>
                </div>

                <p className="sign-up-content" style={{ paddingLeft: "10px" }}>
                  Msg and data rates may apply. Msg frequency varies. Text HELP
                  for help. Text STOP to end.
                  <br />
                  <br />
                  <a
                    className="sign-up-content"
                    target="_blank"
                    style={{ color: "#4d4d4d", textDecoration: "underline" }}
                    href="https://engagedrx.com/va/"
                  >
                    Terms and Conditions
                  </a>
                </p>
                <button
                  type="submit"
                  onClick={this.validateForm.bind(this)}
                  className="sign-up-space sign-up-submit"
                >
                  Get Tips <span aria-hidden="true">&gt;</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
