import React from "react";
import { Link } from "gatsby"
import "./preHeader.css"


const expandISI = () => {
  if(document.getElementById("isi").getBoundingClientRect().top > 1){
    var scrollLoop = setInterval(function(){
      var y = Math.ceil(window.scrollY)
      y = y + 30
      window.scrollTo(0,y)
      document.getElementsByClassName("toggleISI")[0].classList.add("flip")
      
      if (Math.ceil(window.scrollY) < y || document.getElementById("isi").getBoundingClientRect().top < 1){
        clearInterval(scrollLoop)
        document.querySelector(".isi-header").focus();
      }

    },1)
  }
}

const focusISI = (e) => {
  if(e.key != "Tab"){
    expandISI()
  }
}


const PreHeader = ({handleOpen}) => {
    return (
        <div className="pre-header-outer">
        <div className='mobile-pre-header'>
            <div className='row pre-header-row'>
                <div onClick={expandISI} onKeyDown={focusISI} className='item col-6'><Link to="#">Important Safety Information</Link></div>
                <div className='item col-6'><a target="_blank" href="https://amarincorp.com/docs/Vascepa-PI.pdf">Full Prescribing Information</a></div>
            </div>
            <div className="row pre-header-row">
                <div className="item col-6"><a href="https://www.vascepahcp.com/" target="_blank">For Healthcare Professionals</a></div>
                <div className="item col-3"><Link to="/about-vascepa/history">About Amarin</Link></div>
                {/* <div role="button" onClick={handleOpen} onKeyDown={handleOpen} tabIndex="0" className="item mobile-signup col-3">SIGN UP</div> */}
            </div>
            <div className="savings-card-mobile">
            </div>
        </div>
        <div className="pre-header">
          <div className="row pre-header-row">
            <div className= 'pre-header-regular item col-6'><a className="pre-header-regular" onKeyDown={focusISI} onClick={expandISI} href="#">Important Safety Information</a> <span aria-hidden="true">&nbsp;|&nbsp;</span> <a target="_blank" className="pre-header-regular" href="https://amarincorp.com/docs/Vascepa-PI.pdf">Full Prescribing Information</a ></div>
            <div className='pre-header-regular item col-6'><a className="pre-header-regular" href="https://vascepahcp.com/">For Healthcare Professionals</a> <span aria-hidden="true">&nbsp;|&nbsp;</span> <Link className="pre-header-regular" to="/about-vascepa/history">About Amarin</Link> <span aria-hidden="true">&nbsp;|&nbsp;</span> 
            {/* <div role="button" aria-label="Open Sign Up form" style={{display:"inline"}} tabIndex="0" onClick={handleOpen} onKeyDown={handleOpen} on className="pre-header-strong underline" ><span style={{cursor:"pointer"}}> SIGN UP</span></div>&nbsp;<span aria-hidden="true">&nbsp;|&nbsp;</span>&nbsp; */}
            <Link className="pre-header-strong" to="/getting-started/vascepa-savings/">VASCEPA Savings</Link></div>
          </div>
        </div>
        </div>
    )
}


export default PreHeader
